import { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// import STFRecruitForm from './STFRecruitForm';
// import UGRecruitForm from './UGRecruitForm';
// import FundRequestForm from './FundRequestForm';
// import FRSuccessScreen from './FundRequestForm/FRSuccessScreen';
// import FormSuccessPage from './FormSuccess';
// import DonationForm from './DonationForm';
// import DonationSuccessScreen from './DonationForm/DonationSuccessScreen';

const STFRecruitForm = lazy(() => import('./STFRecruitForm'));
const UGRecruitForm = lazy(() => import('./UGRecruitForm'));
const FundRequestForm = lazy(() => import('./FundRequestForm'));
const PUYAFundRequestForm = lazy(() => import('./PUYAFundRequestForm'));
const FRSuccessScreen = lazy(() => import('./FundRequestForm/FRSuccessScreen'));
const FormSuccessPage = lazy(() => import('./FormSuccess'));
const DonationForm = lazy(() => import('./DonationForm'));
const DonationSuccessScreen = lazy(() => import('./DonationForm/DonationSuccessScreen'));
const PurchaseRequestForm = lazy(() => import('./PurchaseRequestForm'));
const PRSuccessScreen = lazy(() => import('./PurchaseRequestForm/PRSuccessScreen'));

export default function MainRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path='/stf_recruits/:formId' exact>
            <STFRecruitForm />
          </Route>
          <Route path='/ug_recruits/:formId' exact>
            <UGRecruitForm />
          </Route>
          <Route path='/fund_request' exact>
            <FundRequestForm />
          </Route>
          <Route path='/puya_fund_request' exact>
            <PUYAFundRequestForm />
          </Route>
          <Route path='/fund_request/success' exact>
            <FRSuccessScreen />
          </Route>
          <Route path='/donation_form' exact>
            <DonationForm />
          </Route>
          <Route path='/donation_form/success' exact>
            <DonationSuccessScreen />
          </Route>
          <Route path='/purchase_request' exact>
            <PurchaseRequestForm />
          </Route>
          <Route path='/purchase_request/success' exact>
            <PRSuccessScreen />
          </Route>
          <Route path='/form_success' exact>
            <FormSuccessPage />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
